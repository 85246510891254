import { defineStore } from "pinia";
import { getAbi, piAbi } from './abi';
import { usdtABI } from './abi/usdtABI';
import { boostProtocolABI } from './abi/boostProtocolABI';
import { ethers } from "ethers";
// import keccak256 from "keccak256";
// import { MerkleTree } from "merkletreejs";

export const useMetatool = defineStore("metatool", () => {

    const factoryContractAddress = window.location.hostname === 'member.boostprotocol.io' || window.location.hostname === 'pre.boostprotocol.io'
        ? '0xEf8c72F0Ca461Db20655323130Efa4D2aA66Aac1' : '0x92EDa1f5311bd7105d9A648Ce1d4821B6172a1d8';

    const usdtAddress = window.location.hostname === 'member.boostprotocol.io' || window.location.hostname === 'pre.boostprotocol.io'
        ? '0xc2132D05D31c914a87C6611C10748AEb04B58e8F' : '0xb63E648a0496DeAe60016182D48daD16c27C51Db';

    const btcAddress = window.location.hostname === 'member.boostprotocol.io' || window.location.hostname === 'pre.boostprotocol.io'
        ? '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c' : '0x36ae3e6c3b53561f1ca983f1e27ca7ec015e61b2';

    // Boost 合约 0x3e122bD0b29151872DFA6aE1f6F921b1EF25C371
    // USDT 合约 0x374a3F171EaD14B5883a9343d31871C4948cBAc2
    // USDTB 合约 0xa0914b85C53b9C65ff0D9869804A684Cd761C45f
    // BoostProtocol 合约 0x7476793C881368c59CAEfC52b641ACf353a5954D 
    // 所有合约Owner权限管理地址 0xfc6a4d842150b9375148662b9B5DD5874d50982F

    // Boost 合约 0x6C8773A9fff0A4232b7a84C65d9965f4e2b5F455
    // USDT 合约 0x25DfB35CFff8753D570b6945589d6524B09db2A3
    // USDTB 合约 0x094b71176262063ab0fB1Dc5A624013c51A2AF49
    // BoostProtocol 合约 0xEF05CC5Bf045d1876A0bd9e43784c62143c32DcF 
    // 所有合约Owner权限管理地址 0xfc6a4d842150b9375148662b9B5DD5874d50982F


    var errors = {};
    var isInit = false;
    var metamaskEnabled = false;
    var currentAccount = '';
    var currentChainId = '';
    var chainIds = [137, 80002];
    var chainIdsData = {
        137: {
            chainId: '0x89',
            chainName: 'Polygon Mainnet',
            chainNameShort: 'Polygon',
            type: 'M',
            nativeCurrency: {
                name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18
            },
            rpcUrls: ['https://polygon-rpc.com/'],
            blockExplorerUrls: ['https://polygonscan.com']
        },
        80002: {
            chainId: '0x13882',
            chainName: 'Polygon Amoy Testnet',
            chainNameShort: 'Polygon',
            type: 'T',
            nativeCurrency: {
                name: 'Polygon',
                symbol: 'MATIC',
                decimals: 18
            },
            rpcUrls: ['https://rpc-amoy.polygon.technology/'],
            blockExplorerUrls: ['https://amoy.polygonscan.com']
        },

    };

    // var tokens = [
    //     {
    //         address: usdtAddress,
    //         symbol: 'TT', 
    //         decimals: 18, 
    //         image: '', 
    //     },
    //     {
    //         address: btcAddress,
    //         symbol: 'BTC', 
    //         decimals: 18, 
    //         image: '', 
    //     },
    // ]

    const endpoints = [
    ]

    let ethersProvider
    let retries = 0
    let isAlive = false

    const initProvider = async () => {
        // reset
        isAlive = false
        retries = 0

        console.log('Initialising Ethers provider', retries)

        while (!isAlive) {
            if (retries > endpoints.length) {
                console.log('All nodes are down!')
                break
            }
            try {
                console.log(`${retries} try, now with enpoint ${endpoints[retries]}`)
                // for WSS
                // ethersProvider = new ethers.providers.WebSocketProvider(endpoints[retries])

                // For HTTP providers
                ethersProvider = new ethers.providers.JsonRpcProvider(endpoints[retries])

                // ethersProvider.ready() does not work 😕
                isAlive = await ethersProvider.getNetwork()
                console.log('isAlive :>> ', isAlive);

                chainIdsData[currentChainId].rpcUrls = [endpoints[retries]];

                // For WSS providers
                // ethersProvider.on('error', async () => {
                //    console.log('Error captured >>>>>>>>', error)
                //    isAlive = false
                //    await initProvider()
                // })
            } catch (error) {
                console.error('Error initialising provider')
                retries++
            }
        }
    }

    // blockchain example method
    const checkBlock = async () => {
        try {
            return await ethersProvider.getBlockNumber()
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    const main = async () => {
        await initProvider()
        let blockN;
        //
        try {
            blockN = await checkBlock()
            console.log('block number is :>> ', blockN)
        } catch (error) {
            initProvider()
        }

        // forces error
        ethersProvider = null


    }

    async function getCurrentAccountFromMetamask() {
        return await window.ethereum.request({ method: 'eth_accounts' });
    }
    async function getCurrentChainIdFromMetamask() {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner();
        const chainId = await signer.getChainId();
        return chainId
    }
    async function getCurrentChainNetworkFromMetamask() {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner();
        const chainId = await signer.getChainId();
        let chainNetwork;
        for (const [key, value] of Object.entries(chainIdsData)) {
            if (key == chainId) {
                chainNetwork = JSON.stringify(value);
            }
        }
        return chainNetwork
    }
    async function requestAccountFromMetamask() {
        try {
            return await window.ethereum.request({ method: 'eth_requestAccounts' });
        } catch (error) {
            errors = {
                requestAccount: error
            }
            return ''
        }
    }
    function checkIsMetamaskEnabled() {
        return typeof window.ethereum !== 'undefined'
    }
    async function connectWallet() {
        if (checkIsMetamaskEnabled()) {
            metamaskEnabled = true;
            let rs = await getCurrentAccountFromMetamask()

            if (rs.length == 0) {
                rs = await requestAccountFromMetamask()
            }
            console.log('rs', rs);
            currentAccount = rs[0] || '';

            currentChainId = await getCurrentChainIdFromMetamask();

            console.log(currentChainId);

            // await main();

            if (window.location.hostname === 'member.boostprotocol.io' || window.location.hostname === 'pre.boostprotocol.io') {
                if (currentChainId != 137)
                    await switchChainId(137);

            } else {
                if (currentChainId != 80002)
                    await switchChainId(80002);

            }



            return rs;
        } else {
            if (window.outerWidth < 480) {
                alert("Please install Metamask and use it's internal browser");

            } else {
                window.open('https://metamask.io/download/', '__blank');

            }
        }
    }
    async function switchChainId(id) {
        try {
            var tmp = {}
            tmp['chainId'] = chainIdsData[id].chainId;
            tmp['chainName'] = chainIdsData[id].chainName;
            tmp['rpcUrls'] = chainIdsData[id].rpcUrls;
            tmp['nativeCurrency'] = chainIdsData[id].nativeCurrency;
            tmp['blockExplorerUrls'] = chainIdsData[id].blockExplorerUrls;
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    tmp
                ],
            });
            return true
        } catch (error2) {
            console.log(error2)
            errors = {
                addEthereumChain: error2
            }
            return false
        }
    }
    async function getBalanceFromMetamask() {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        let balance = await provider.getBalance(currentAccount)
        balance = ethers.utils.formatEther(balance)
        console.log(balance);
        return balance;
    }

    async function checkContract(contractAddress) {
        if (checkIsMetamaskEnabled()) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const myContract = new ethers.Contract(contractAddress, getAbi(), signer);
            const name = await myContract.name();
            const symbol = await myContract.symbol();
            const baseSettings = await myContract.baseSettings();
            const baseURI = await myContract._baseTokenURI();
            const isPublicActive = await myContract.isPublicActive();

            return {
                name,
                symbol,
                baseSettings,
                isPublicActive,
                baseURI
            }
        }
        return null
    }

    async function getSignature(message) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();
        const signature = await signer.signMessage(message);

        return signature

    }

    async function checkAllowance(amount) {

        var address = usdtAddress;

        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();
        if (address === ethers.constants.AddressZero) {
            return
        }

        const erc20 = new ethers.Contract(address, usdtABI(), signer);
        const allowance = await erc20.allowance(await signer.getAddress(), factoryContractAddress);

        var formattedNumber = ethers.utils.parseUnits(amount.toString(), "ether");

        if (amount > 0 && allowance.lt(formattedNumber)) {
            return {
                res: false,
            }
        } else {
            return {
                res: true,
            }
        }

    }

    async function approve(amount) {

        var address = usdtAddress;

        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();
        if (address === ethers.constants.AddressZero) {
            return
        }

        const erc20 = new ethers.Contract(address, usdtABI(), signer);
        const allowance = await erc20.allowance(await signer.getAddress(), factoryContractAddress);

        var formattedNumber = ethers.utils.parseUnits(amount.toString(), "ether");
        var allowanceAmount = ethers.utils.parseUnits('100000', "ether");

        if (amount > 0 && allowance.lt(formattedNumber)) {
            const approveTx = await erc20.approve(factoryContractAddress, allowanceAmount, {
                // gasPrice: await signer.provider.getGasPrice() 
                // gasLimit: 800000,
            });

            try {
                await approveTx.wait();
                return {
                    wait: approveTx.wait()
                }
            } catch (err) {
                return { error: err.error };

            }
        } else {
            return {
                res: true,
            }
        }

    }

    async function deposit(amount, bnbAmount) {

        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();
        const myContract = new ethers.Contract(factoryContractAddress, getAbi(), signer);

        var formattedNumber = ethers.utils.parseUnits(amount.toString(), "ether");
        var formattedNumberBNB = ethers.utils.parseEther(bnbAmount.toString());

        var options = { value: formattedNumberBNB };

        try {
            let res = await myContract.deposit(formattedNumber, options);

            return {
                res: res,
                wait: res.wait()
            }
        } catch (err) {
            return { error: err };

        }

    }

    async function accounts(addr) {

        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();

        const myContract = new ethers.Contract(factoryContractAddress, boostProtocolABI(), signer);

        let res = await myContract.accounts(addr);

        return res;
    }

    async function getPrice() {

        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();

        const myContract = new ethers.Contract(factoryContractAddress, boostProtocolABI(), signer);

        let res = await myContract.getPrice();

        return ethers.utils.formatUnits(res, "ether");
    }

    async function stakingOptions() {
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();

        const myContract = new ethers.Contract(factoryContractAddress, getAbi(), signer);

        let res;

        try {
            res = await myContract.stakingOptions();
        } catch (error) {
            console.log(error)
        }

        return res;
    }

    async function pledge(userId, amount) {

        // console.log(signature);
        console.log(userId);
        console.log(amount);
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();
        const myContract = new ethers.Contract(factoryContractAddress, boostProtocolABI(), signer);

        try {
            let res = await myContract.pledge(userId, amount,
                {
                    gasLimit: 3000000,
                    gasPrice: ethers.utils.parseUnits('60', 'gwei')
                }
            );

            return {
                res: res,
                wait: res.wait()
            }
        } catch (err) {
            // console.log(err);
            return { error: err };

        }

    }

    async function members() {
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();

        const myContract = new ethers.Contract('0x498fE3075E7dba58fc8799239f55bAEe88A89984', usdtABI(), signer);
        // const myContract = new ethers.Contract('0x498fE3075E7dba58fc8799239f55bAEe88A89984', usdtABI(), signer);

        let res;

        try {
            res = await myContract.members(signer.getAddress());
        } catch (error) {
            console.log(error)
        }

        return res;
    }

    async function withdraw(signature, amount, orderId, deadline) {

        console.log(signature);
        console.log(amount);
        console.log(orderId);
        console.log(deadline);
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();

        const myContract = new ethers.Contract(factoryContractAddress, getAbi(), signer);

        let res = await myContract.withdraw(signature, amount, orderId, deadline);

        return {
            contract: res,
            wait: res.wait()
        }
    }

    async function getInviter() {

        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();

        const myContract = new ethers.Contract(factoryContractAddress, getAbi(), signer);

        let address = await myContract.members(currentAccount);

        console.log(address)

        return address[0];
    }

    async function getPoolInfos() {

        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();

        const myContract = new ethers.Contract(factoryContractAddress, getAbi(), signer);

        let res = await myContract.getPoolInfos();

        return res;
    }

    async function bindInviter(address) {

        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();

        const myContract = new ethers.Contract(factoryContractAddress, getAbi(), signer);

        let res;


        res = await myContract.bindInviter(address);

        return {
            res: res,
            wait: res.wait()
        }
    }

    async function getBalance(currentAccount) {
        var address = usdtAddress;

        console.log('type: ' + address);

        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();

        const myContract = new ethers.Contract(address, usdtABI(), signer);

        let res = await myContract.balanceOf(currentAccount);

        return ethers.utils.formatUnits(res.toString(), "mwei");
    }

    async function transfer(type, amount, receiver) {
        var address = '';

        switch (type) {
            case 0:
                address = usdtAddress;
                break;
            case 1:
                address = btcAddress;
                break;
        }

        console.log('type: ' + address);

        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const signer = provider.getSigner();

        const myContract = new ethers.Contract(address, piAbi(), signer);

        var formattedNumber = ethers.BigNumber.from(amount).mul(ethers.BigNumber.from(10).pow(18))

        let res = await myContract.transfer(receiver, formattedNumber);

        return {
            res: res,
            wait: res.wait()
        }
    }

    async function getTransactionDetails(txid) {
        try {
            // Get transaction receipt
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const receipt = await provider.getTransactionReceipt(txid);
            console.log('Transaction Receipt:', receipt);

            // Decode logs
            const myContract = new ethers.Contract(factoryContractAddress, boostProtocolABI(), signer);
            receipt.logs.forEach(log => {
                try {
                    const parsedLog = myContract.interface.parseLog(log);
                    console.log('Parsed Log:', parsedLog);
                } catch (e) {
                    console.log('Log could not be parsed by this contract:', log);
                }
            });

            // Example: Read contract state (replace YOUR_CONTRACT_METHOD with the actual method)
            //   const result = await contract.YOUR_CONTRACT_METHOD();
            //   console.log('Contract State:', result);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    metamaskEnabled = checkIsMetamaskEnabled();
    if (metamaskEnabled) {
        getCurrentAccountFromMetamask().then(acc => {
            currentAccount = acc[0]
        });
        getCurrentChainIdFromMetamask().then(chainId => {
            currentChainId = chainId
        })

        window.ethereum.on('accountsChanged', async (accounts) => {
            if (accounts.length === 0) {
                currentAccount = ''
                console.log('Please connect to MetaMask.');
                localStorage.removeItem('finioToken');
                window.location.reload();
            } else if (accounts[0] !== currentAccount) {
                if (currentAccount) {
                    localStorage.removeItem('finioToken');
                    window.location.reload();

                }
                currentAccount = accounts[0]
                console.log('accountsChanged', accounts);
            }
        });

        window.ethereum.on('chainChanged', (chainId) => {
            currentChainId = ethers.BigNumber.from(chainId).toNumber()
            console.log('chainChanged', ethers.BigNumber.from(chainId).toNumber(), chainId);
            localStorage.removeItem('finioToken');
            // window.location.reload();
        });
        isInit = true;
    } else {
        currentAccount = ''
        console.log('Please install MetaMask.');
    }

    return {
        errors,
        factoryContractAddress,
        usdtAddress,
        btcAddress,
        metamaskEnabled,
        currentAccount,
        currentChainId,
        chainIds,
        // chainIdsTestNet, chainIdsMainnet,
        chainIdsData,
        isInit,
        main,
        getBalance,
        getBalanceFromMetamask,
        getCurrentAccountFromMetamask,
        checkIsMetamaskEnabled,
        connectWallet,
        switchChainId,
        checkContract,
        getCurrentChainNetworkFromMetamask,
        getCurrentChainIdFromMetamask,
        approve,
        deposit,
        withdraw,
        getSignature,
        getInviter,
        getPoolInfos,
        bindInviter,
        members,
        stakingOptions,
        pledge,
        transfer,
        checkAllowance,
        getTransactionDetails,
        accounts,
        getPrice
    }
})